import {
  getMultiAuthRequestReasonsOptions,
  sendOtpForDoubleAuthentication,
} from 'services/common';
import { APIResponse, AppThunkAction } from 'types';
import { AppDispatch } from 'store';
import {
  SECURE_ACTION_FOR_DOUBLE_AUTHENTICATION,
  RESET_DATA_FOR_DOUBLE_AUTHENTICATION,
  FETCH_OPTIONS_FOR_DOUBLE_AUTHENTICATED_REASONS_STARTED,
  FETCH_OPTIONS_FOR_DOUBLE_AUTHENTICATED_REASONS_SUCCEEDED,
  FETCH_OPTIONS_FOR_DOUBLE_AUTHENTICATED_REASONS_FAILED,
  SEND_OTP_FOR_DOUBLE_AUTHENTICATION_SUCCEEDED,
  RESET_OTP_SENT_FLAG_FOR_DOUBLE_AUTHENTICATION,
} from './constants';

export const secureTheAction = (action: AppThunkAction, data: object) => ({
  type: SECURE_ACTION_FOR_DOUBLE_AUTHENTICATION,
  action,
  data,
});

const fetchMultiAuthenticatedReasonOptionsStarted = () => ({
  type: FETCH_OPTIONS_FOR_DOUBLE_AUTHENTICATED_REASONS_STARTED,
});

const fetchMultiAuthenticatedReasonOptionsFailed = () => ({
  type: FETCH_OPTIONS_FOR_DOUBLE_AUTHENTICATED_REASONS_FAILED,
});

const fetchMultiAuthenticatedReasonOptionsSucceeded = (data: object) => ({
  type: FETCH_OPTIONS_FOR_DOUBLE_AUTHENTICATED_REASONS_SUCCEEDED,
  data,
});

const sendOtpForDoubleAuthenticationSucceeded = (data: object) => ({
  type: SEND_OTP_FOR_DOUBLE_AUTHENTICATION_SUCCEEDED,
  data,
});

export const resetOtpSentFlagForDoubleAuthenitcation = () => ({
  type: RESET_OTP_SENT_FLAG_FOR_DOUBLE_AUTHENTICATION,
});

export const resetDataForDoubleAutheticationCall = () => ({
  type: RESET_DATA_FOR_DOUBLE_AUTHENTICATION,
});

// redux thunk actions
export const secureActionWithDoubleAuthentication =
  (action: AppThunkAction, data: object) => (dispatch: AppDispatch) => {
    dispatch(secureTheAction(action, data));
  };

export const resetDataForDoubleAuthetication =
  () => (dispatch: AppDispatch) => {
    dispatch(resetDataForDoubleAutheticationCall());
  };

export const fetchDropdownOptionsForMultiAuthentication =
  (code: string) => (dispatch: AppDispatch) => {
    dispatch(fetchMultiAuthenticatedReasonOptionsStarted);
    getMultiAuthRequestReasonsOptions(code)
      .then((response: APIResponse) => {
        dispatch(fetchMultiAuthenticatedReasonOptionsSucceeded(response.data));
      })
      .catch(() => {
        dispatch(fetchMultiAuthenticatedReasonOptionsFailed());
      });
  };

export const sendOtpForDoubleAuthenticationAction =
  (params: object) => (dispatch: AppDispatch) => {
    sendOtpForDoubleAuthentication(params)
      .then((response: APIResponse) => {
        dispatch(sendOtpForDoubleAuthenticationSucceeded(response.data));
      })
      .catch(() => {});
  };
