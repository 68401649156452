import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import NavItem from 'react-bootstrap/lib/NavItem';
import Nav from 'react-bootstrap/lib/Nav';
import Tab from 'react-bootstrap/lib/Tab';
import smoothscroll from 'smoothscroll-polyfill';
import Emptyview from 'components/Emptyview';
import SurveyCard from 'components/SurveyCard';
import TabContentWrapper from 'containers/PatientDetails/Components/TabContentWrapper';
import PropTypes from 'prop-types';
import { getSurvey } from './action';
import NoSurvey from '../../assets/images/nosurvey.png';

// kick off the polyfill!
import './surveys.scss';
import SurveyQuestions from './Components/SurveyQuestions';

smoothscroll.polyfill();

const Surveys = props => {
  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const [patientId, procedureId] = [
    parseInt(params.patientId, 10) || -1,
    parseInt(params.procedureId, 10) || -1,
  ];

  const [selectedSurvey, setSelectedSurvey] = useState(null);

  const surveys = useSelector(store => store.surveys.surveys);
  const patient = useSelector(store => store.currentPatientInfo);
  const currentUser = useSelector(store => store.currentUser.attributes);
  const selectedPhaseId = useSelector(
    store => store.currentPatientInfo.selectedPhaseId
  );
  const { isActive } = props;

  useEffect(() => {
    if (isActive) dispatch(getSurvey(patientId, procedureId, selectedPhaseId));
    if (!isActive) setSelectedSurvey(null);
  }, [selectedPhaseId, procedureId, isActive]);

  const onPrintSurvey = () => {
    window.print();
    return false;
  };

  const handleSelectTab = (event, survey) => {
    event.preventDefault();
    setSelectedSurvey(survey);
    return false;
  };

  const onEditSurvey = (event, eventId) => {
    event.stopPropagation();
    history.push(
      `/patients/${patientId}/procedures/${procedureId}/surveys/${eventId}`
    );
    return false;
  };

  return (
    <div>
      <TabContentWrapper
        className='survey-tab-wrapper'
        body={
          surveys.length > 0 ? (
            <Tab.Container id='survey-tabs' defaultActiveKey={surveys[0].id}>
              <div className='clearfix'>
                <div className='show-only-in-print selected-survey-name'>
                  {selectedSurvey
                    ? `${`Patient ID: ${
                        patient.patientMrn || patientId
                      }, Survey: ${selectedSurvey.surveyName}`}`
                    : 'Select a Survey'}
                </div>
                <div className='survey-container'>
                  <div className='tabs-container p-r-0 hide-in-print'>
                    <div className='selected-survey-container section-to-print'>
                      {selectedSurvey
                        ? selectedSurvey.surveyName
                        : 'Select a Survey'}
                      {selectedSurvey ? (
                        <span
                          className='cursor-pointer icon icon-font-a-print primary-text-color pull-right print-option'
                          onClick={onPrintSurvey}
                          onKeyDown={() => {}}
                          role='button'
                          tabIndex={0}
                          aria-label='iconButton'
                        />
                      ) : (
                        ''
                      )}
                    </div>
                    <Nav bsStyle='tabs' stacked>
                      {surveys.map(survey => (
                        <NavItem
                          eventKey={survey.id}
                          key={survey.id}
                          onClick={e => handleSelectTab(e, survey)}
                          className={selectedSurvey === '' ? 'first-view' : ''}
                        >
                          <SurveyCard
                            key={survey.id}
                            survey={survey}
                            onEditSurvey={onEditSurvey}
                            header
                            currentUser={currentUser}
                          />
                        </NavItem>
                      ))}
                    </Nav>
                  </div>
                  <div className='p-l-0 tabs-content-container'>
                    {selectedSurvey ? (
                      <Tab.Content animation>
                        <SurveyQuestions
                          survey={
                            surveys.filter(
                              survey => survey.id === selectedSurvey.id
                            )[0]
                          }
                        />
                      </Tab.Content>
                    ) : (
                      <div className='no-selected-survey-container'>
                        <div>
                          <img src={NoSurvey} alt='' />
                        </div>
                        <div>Select a survey to view response</div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </Tab.Container>
          ) : (
            <Emptyview imgSrc={NoSurvey} className='' message='No Surveys' />
          )
        }
      />
    </div>
  );
};

Surveys.propTypes = {
  isActive: PropTypes.bool.isRequired,
};
export default Surveys;
